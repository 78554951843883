import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"

export default ({ data }) => {
  const posts = data.sortedPosts.entries
  const { title, bodyBgColor, bodyTextColor, bodyTitleColor, seo } = data.contentfulStaticPage

  const linkUrl = (post) => {
    const typeSlugs = {
      'ContentfulProject': '/work/',
      'ContentfulPost': '/news/'
    }
    if( typeSlugs[post.__typename] !== undefined ){
      return typeSlugs[post.__typename] + post.slug + '/'
    }
    return '/' + post.slug + '/' /// fallback should never happen
  }

  const renderTag = (itemNode) => {
    if( itemNode.__typename === 'ContentfulProject') {
      let tags = itemNode?.tags ?? []
      tags = tags.map( tag => tag.title)
  
      return (
        <span>
          {itemNode.client}
          { tags.length > 0 &&
            <> - {tags.join(', ')}</>
          }
        </span>
      )
    }

    return (
      <span>
        {itemNode.datePublished ? itemNode.datePublished : itemNode.createdAt}
      </span>
    )
  }

  return (
    <Layout
      bodyBgColor={bodyBgColor}
      bodyTextColor={bodyTextColor}
      bodyTitleColor={bodyTitleColor}
      pageTitle={seo && seo.seoTitle ? seo.seoTitle : title}
      pageDescription={seo && seo.description ? seo.description : null}
      pageImage={seo && seo.image ? seo.image.file.url : null}
      pageArticle={seo && seo.article ? seo.article : false}
      pathname={'/news'}
    >
      <div className="container mt-32 md:mt-42">
        <h1>{title}</h1>


      {
        posts &&
        <div className="mt-16 md:mt-26 mb-10 flex flex-wrap -mx-12">
          {posts.map((post, index) => (
            <div key={index} className="w-full md:w-1/2 px-12 mb-20 md:mb-26">
              <Link to={linkUrl(post)} className="group-hover">
                {post.featuredImage && <Img fluid={post.featuredImage.fluid} className="mb-6" />}
                { renderTag(post) }
                <h3 className="mt-2 underline group-hover:textDecoration-none">{post.title}</h3>
              </Link>
            </div>
          ))}
        </div>
      }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query newsQuery {
    contentfulStaticPage(slug: {eq: "news"}) {
      seo {
        ... on Node {
            ... on ContentfulSeo {
            article
            seoTitle
            description
            image {
              file {
                url
              }
            }
          }
        }
      }
      title
      bodyBgColor
      bodyTextColor
      bodyTitleColor
    }
    sortedPosts: contentfulSortContent(slug: {eq: "news"}) {
      entries {
        __typename
        ... on Node {
          ... on ContentfulProject {
            createdAt(formatString: "MMMM D, Y")
            featuredImage {
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            intro {
              childMarkdownRemark {
                excerpt(format: PLAIN, pruneLength: 250, truncate: true)
              }
            }
            slug
            title
            client
            tags {
              title
            }
          }
          ... on ContentfulPost {
            createdAt(formatString: "MMMM D, Y")
            datePublished(formatString: "MMMM D, Y")
            featuredImage {
              fluid(maxWidth: 1020, quality: 70) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            intro {
              childMarkdownRemark {
                excerpt(format: PLAIN, pruneLength: 250, truncate: true)
              }
            }
            slug
            title
          }
        }
      }
    }
  }
`
